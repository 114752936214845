import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import {
  BigNumber,
  ethers,
  utils,
  ContractTransaction,
  providers,
} from 'ethers';
import { ApexOptions } from 'apexcharts';
import { HiArrowNarrowRight } from 'react-icons/hi';
import Lottie from 'react-lottie';
import {
  Banner,
  Card,
  Modal,
  ModalNeed,
  ModalAmount,
  RangeCentersYearly,
  RangeCentersMonthly,
  RangeNetworkYearly,
  RangeNetworkMonthly,
} from './styles';

import {
  formatPrice,
  formatUSDValueFromWei,
  formatEthValue,
} from '~/utils/format';
import stakingTerms from '~/assets/animations/staking-terms-details.json';

import api from '~/services/api';
import { useResize } from '~/hooks/Resize';
import { web3store } from '~/store';
import WalletAvatar from '~/components/WalletAvatarAutoAffiliate';
import ethLogo from '~/assets/logo/eth.png';
import usdLogo from '~/assets/logo/usd.png';
import arrow from '~/assets/logo/arrow.svg';
import ethLogoWhite from '~/assets/icons/logo-eth.svg';
import usdcLogo from '~/assets/icons/logo-usdc.svg';
import bnbLogo from '~/assets/logo/logo-bnb-border-white.svg';
import usdpiLogo from '~/assets/logo/logo-usdpi-border-white.svg';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import swalError from '~/utils/swalError';
import Loading from '~/components/Loading';
import WalletRowAutoAffiliate from '~/components/WalletRowAutoAffiliate';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';
import ModalC14 from '~/components/ModalC14';
import { abi as EthUsdtSwapV3Abi } from '~/abi/EthUsdtSwapV3.json';
import { abi as USDPIAbi } from '~/abi/usdpi.json';
import ModalPleaseConfirm from '~/components/ModalPleaseConfirm';

interface IDemoEarning {
  5: string;
  30: string;
  155: string;
  780: string;
  3905: string;
  19530: string;
  FINAL: string;
}

interface IDemoEarnings {
  earnings: {
    annual: IDemoEarning[];
    monthly: IDemoEarning[];
  };
}

interface IParams {
  slug: string;
}

interface EthNeeded {
  wei: string;
  formatted: string;
}

interface AmtNeeded {
  usdWei: string;
  usdWeiFull: string;
  ethWei: string;
}

const OrderOption: React.FC = () => {
  // console.log('+++++ OrderOption +++++');
  const history = useHistory();
  const params = useParams<IParams>();
  // console.log(params);
  const { width } = useResize();
  const [selectCoin, setSelectCoin] = useState('usdpi');
  const [activePayment, setActivePayment] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [percentage, setPercentage] = useState(133);
  const [btnYearlyMonthly, setBtnYearlyMonthly] = useState('yearly');
  const [cartProfitCentersLeft, setCartProfitCentersLeft] = useState(6);
  const [cartProfitCentersRight, setCartProfitCentersRight] = useState(1);
  const [cartPrepayMonths, setCartPrepayMonths] = useState(1);
  const [show, setShow] = useState(false);
  const [showNeed, setShowNeed] = useState(false);
  const [showAmount, setShowAmount] = useState(false);
  const [percentageBarCenterYearly, setPercentageBarCenterYearly] = useState(1);
  const [percentageBarNetworkYearly, setPercentageBarNetworkYearly] =
    useState(0);

  const [percentageBarCenterMonthly, setPercentageBarCenterMonthly] =
    useState(1);
  const [percentageBarNetworkMonthly, setPercentageBarNetworkMonthly] =
    useState(0);
  const [multiple, setMultiple] = useState(50);
  const [valueSelected, setValueSelected] = useState(100);
  const [demoEarnings, setDemoEarnings] = useState({} as IDemoEarnings);
  const [demoEarningPrice, setDemoEarningPrice] = useState('$0');
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const [account, setAccount] = web3store.useState('account');
  const [formattedEthValue, setFormattedEthValue] = useState('');
  const [formattedEthValueRight, setFormattedEthValueRight] = useState('');
  const [loading, setLoading] = useState(false);
  const [processText, setProcessText] = web3store.useState('processText');
  const [ethNeededLeft, setEthNeededLeft] = useState<EthNeeded>({
    wei: '0', // Initialize with a valid wei value
    formatted: '0.0',
  });
  const [ethNeededRight, setEthNeededRight] = useState<EthNeeded>({
    wei: '0', // Initialize with a valid wei value
    formatted: '0.0',
  });
  const [amtOwedLeft, setAmtOwedLeft] = useState<AmtNeeded>({
    usdWei: '0',
    usdWeiFull: '0',
    ethWei: '0',
  });
  const [amtOwedRight, setAmtOwedRight] = useState<AmtNeeded>({
    usdWei: '0',
    usdWeiFull: '0',
    ethWei: '0',
  });
  const [amtOwedBuyFiat, setAmtOwedBuyFiat] = useState<AmtNeeded>({
    usdWei: '0',
    usdWeiFull: '0',
    ethWei: '0',
  });
  const [amtNeededActivate, setAmtNeededActivate] = useState<string | null>(
    null
  );
  const [profitCentersNeededActivate, setProfitCentersNeededActivate] =
    useState<number | null>(null);
  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);
  const [ethBalance, setEthBalance] = useState('0');
  const [usdpiBalance, setUsdpiBalance] = useState('0');
  const [lastUsdpiBalance, setLastUsdpiBalance] = useState('0');
  const [imgURL, setImgURL] = useState<string | null>(null);
  const [c14Src, setC14Src] = useState<string | null>(null);
  const [sponsorName, setSponsorName] = useState<string | null>(null);
  // const [promiseResolve, setPromiseResolve] = useState<
  //   ((value?: any) => void) | null
  // >(null);
  // const [promiseReject, setPromiseReject] = useState<
  //   ((reason?: any) => void) | null
  // >(null);
  const promiseResolveRef = useRef<((value: unknown) => void) | null>(null);
  const promiseRejectRef = useRef<((reason?: any) => void) | null>(null);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const location = useLocation();
  const receivedData = location.state as
    | {
        selectedAddress: string;
        reference: string;
        placementRef: string;
        username: string;
        sponsorName: string;
        sponsorUsername: string;
      }
    | undefined;
  const [isC14Open, setIsC14Open] = useState(true);
  const externalSiteURL = 'https://example.com'; // Replace with the URL of the external site
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const closeC14Modal = () => {
    setIsC14Open(false);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ON LOAD
  useEffect(() => {
    // This code will run only once when the component mounts (page loads)
    // Check to see if receivedData is populated by previous page.
    // if not, bounce the user back to start of route
    if (!receivedData) {
      history.push({
        pathname: '/aa',
      });
    }
  }, []); // The empty dependency array ensures this effect runs only once

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },

      fill: {
        type: 'solid',
        colors: ['#9DF3FF', '#884BFD'],
      },

      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        width: 0,
      },
      tooltip: {
        enabled: false,
      },
    };

    return data;
  }, []);

  const series = useMemo(() => {
    let total = 0;
    let costPercent = 0;
    let earningPercent = 0;
    const demoEarning = parseFloat(
      demoEarningPrice.replace('$', '').replaceAll(',', '')
    );
    if (btnYearlyMonthly === 'yearly') {
      total = percentageBarCenterYearly * 50 * 12 + demoEarning;
      costPercent = (percentageBarCenterYearly * 50 * 12 * 100) / total;
      earningPercent = (demoEarning * 100) / total;
    } else {
      total = percentageBarCenterMonthly * 50 + demoEarning;
      costPercent = (percentageBarCenterMonthly * 50 * 100) / total;
      earningPercent = (demoEarning * 100) / total;
    }
    costPercent = costPercent <= 5 ? 5 : costPercent;
    costPercent = costPercent >= 95 ? 95 : costPercent;
    earningPercent = earningPercent <= 5 ? 5 : earningPercent;
    earningPercent = earningPercent >= 95 ? 95 : earningPercent;

    return [costPercent, earningPercent];
  }, [
    btnYearlyMonthly,
    demoEarningPrice,
    percentageBarCenterMonthly,
    percentageBarCenterYearly,
  ]);

  useEffect(() => {
    api.get('v1/demo/earnings').then((response) => {
      setDemoEarnings(response.data);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(demoEarnings).length > 0) {
      let networkSize: 5 | 30 | 155 | 780 | 3905 | 19530 | 'FINAL' = 5;
      let data = '0';
      if (btnYearlyMonthly === 'yearly') {
        switch (percentageBarNetworkYearly) {
          case 1:
            networkSize = 30;
            break;

          case 2:
            networkSize = 155;
            break;

          case 3:
            networkSize = 780;
            break;

          case 4:
            networkSize = 3905;
            break;

          case 5:
            networkSize = 19530;
            break;

          case 6:
            networkSize = 'FINAL';
            break;

          default:
            networkSize = 5;
            break;
        }

        data =
          demoEarnings.earnings.annual[percentageBarCenterYearly][networkSize];
      } else {
        switch (percentageBarNetworkMonthly) {
          case 1:
            networkSize = 30;

            break;
          case 2:
            networkSize = 155;

            break;
          case 3:
            networkSize = 780;

            break;
          case 4:
            networkSize = 3905;

            break;
          case 5:
            networkSize = 19530;
            break;

          case 6:
            networkSize = 'FINAL';
            break;

          default:
            networkSize = 5;
            break;
        }

        data =
          demoEarnings.earnings.monthly[percentageBarCenterMonthly][
            networkSize
          ];
      }
      setDemoEarningPrice(data || '$0');
    }
  }, [
    btnYearlyMonthly,
    demoEarnings,
    percentageBarCenterMonthly,
    percentageBarCenterYearly,
    percentageBarNetworkMonthly,
    percentageBarNetworkYearly,
  ]);

  useEffect(() => {
    setActivePayment(true);
    setTimeout(() => {
      if (width <= 991) {
        const element = document.getElementById('resum');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    }, 50);
  }, [width]);

  useEffect(() => {
    switch (percentage) {
      case 200: {
        setMultiple(600);
        break;
      }
      case 175: {
        setMultiple(300);
        break;
      }
      case 150: {
        setMultiple(150);
        break;
      }
      case 133: {
        setMultiple(50);
        break;
      }
      default:
      // console.log('multiple');
    }
  }, [cartPrepayMonths, cartProfitCentersLeft, percentage]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // Method assumes amt2Adjust is USD amount in usdt wei(6) format.
  // Balance is in usdpi string wei(18) format
  // Return value will be USD amount in usdt wei (6) format.
  const adjustForUsdpiBalance = useCallback(
    (amt2Adjust: string) => {
      let retVal = amt2Adjust;
      const bn_amt2Adjust = BigNumber.from(amt2Adjust).mul(
        BigNumber.from(10).pow(12)
      );
      const bn_usdpiBalance = BigNumber.from(usdpiBalance);
      if (!bn_usdpiBalance.isZero() && !bn_amt2Adjust.isZero()) {
        // Only adjust if balance and amt are not zero
        if (bn_usdpiBalance.gte(bn_amt2Adjust)) {
          retVal = '0'; // User balance already gte amt needed
        } else {
          const difference = bn_amt2Adjust.sub(bn_usdpiBalance);
          retVal = difference.div(BigNumber.from(10).pow(12)).toString();
        }
      }
      return retVal;
    },
    [usdpiBalance]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getQuote = async (usdpiNeeded: string): Promise<string> => {
    // console.log('+++++ getQuote +++++');
    // console.log('+++++ usdpiNeeded: %s +++++', usdpiNeeded);
    try {
      const response = await api.get(
        `v1/exchange/quote/USD/ETH/${usdpiNeeded}`
      );
      const net = response.data.estimate.net / 10 ** 18;
      // console.log('New net: %s', net);
      const ethWei = utils.parseUnits(net.toString(), 18).toString();
      const formattedValue = formatEthValue(ethWei);
      // console.log('+++++ formattedValue: %s +++++', formattedValue);
      return ethWei;
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Request canceled:', error.message);
      } else {
        // Handle other errors
      }
      throw error; // Re-throw the error so it can be handled outside
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // START REMOVE FOR PROD
  // useEffect(() => {
  //   console.log('+++ amtOwedLeft +++');
  //   console.log(amtOwedLeft);
  // }, [amtOwedLeft]);

  // useEffect(() => {
  //   console.log('+++ amtOwedRight +++');
  //   console.log(amtOwedRight);
  // }, [amtOwedRight]);
  // END REMOVE FOR PROD
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // LEFT SELECTION
  useEffect(() => {
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('LEFT SELECTION');
    // console.log('multiple: %s', multiple);
    // console.log('cartProfitCentersLeft: %s', cartProfitCentersLeft);
    // console.log('usdpiBalance: %s', usdpiBalance);
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    const newUSD = utils
      .parseUnits(
        (
          multiple * cartProfitCentersLeft +
          cartProfitCentersLeft * 25 +
          0
        ).toFixed(6),
        6
      )
      .toString();
    // console.log('newUSD - left: %s', newUSD);
    const newUSDWithFee = utils
      .parseUnits(
        (
          multiple * cartProfitCentersLeft +
          cartProfitCentersLeft * 25 +
          8
        ).toFixed(6),
        6
      )
      .toString();
    // console.log('newUSDWithFee - left: %s', newUSDWithFee);

    const adjustedUSDWithFee = adjustForUsdpiBalance(newUSDWithFee);
    // console.log('adjustedUSDWithFee - left: %s', adjustedUSDWithFee);

    const controller = new AbortController();
    setFormattedEthValue('...');

    const newAmt = { usdWei: newUSD, usdWeiFull: newUSDWithFee, ethWei: '0' };
    setAmtOwedLeft(newAmt);

    // Use getQuote function to get the ETH value
    getQuote(adjustedUSDWithFee)
      .then((ethWei) => {
        const formattedValue = formatEthValue(ethWei);
        setFormattedEthValue(formattedValue);
        setAmtOwedLeft((prevAmt) => ({ ...prevAmt, ethWei }));
        setFormattedEthValue(formattedValue);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Request canceled:', error.message);
        } else {
          // Handle other errors
        }
      });

    return () => {
      controller.abort(); // Cancel the request when the component unmounts or effect re-runs
    };
  }, [multiple, cartProfitCentersLeft, usdpiBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // RIGHT SELECTION
  useEffect(() => {
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('RIGHT SELECTION');
    // console.log('multiple: %s', multiple);
    // console.log('cartProfitCentersRight: %s', cartProfitCentersRight);
    // console.log('usdpiBalance: %s', usdpiBalance);
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    // console.log('+++++++++++++++++++++++++++++++++++++');
    const newUSD = utils
      .parseUnits(
        (
          multiple * cartProfitCentersRight +
          cartProfitCentersRight * 25 +
          0
        ).toFixed(6),
        6
      )
      .toString();
    // console.log('newUSD: %s', newUSD);
    const newUSDWithFee = utils
      .parseUnits(
        (
          multiple * cartProfitCentersRight +
          cartProfitCentersRight * 25 +
          8
        ).toFixed(6),
        6
      )
      .toString();
    // console.log('newUSDWithFee: %s', newUSDWithFee);

    const adjustedUSDWithFee = adjustForUsdpiBalance(newUSDWithFee);
    // console.log('adjustedUSDWithFee: %s', adjustedUSDWithFee);

    const controller = new AbortController();
    setFormattedEthValueRight('...');

    const newAmt = { usdWei: newUSD, usdWeiFull: newUSDWithFee, ethWei: '0' };
    setAmtOwedRight(newAmt);

    // Use getQuote function to get the ETH value
    getQuote(adjustedUSDWithFee)
      .then((ethWei) => {
        const formattedValue = formatEthValue(ethWei);
        setFormattedEthValueRight(formattedValue);
        setAmtOwedRight((prevAmt) => ({ ...prevAmt, ethWei }));
        setFormattedEthValueRight(formattedValue);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Request canceled:', error.message);
        } else {
          // Handle other errors
        }
      });

    return () => {
      controller.abort(); // Cancel the request when the component unmounts or effect re-runs
    };
  }, [multiple, cartProfitCentersRight, usdpiBalance]);

  const handleCloseModalConfirm = useCallback(() => {
    setShowModalConfirm(false);
  }, []);

  const handleBtnYearly = useCallback((e) => {
    setBtnYearlyMonthly(e);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowNeed(false);
    setShowAmount(false);
  }, []);

  const handleShow = useCallback(() => setShow(true), []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // BUY ETH NOW BUTTON
  const handleBuyNowButtonClickOld = () => {
    // Open a new tab with the specified URL
    window.open('https://pay.c14.money/', '_blank');
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // BUY USDPI NOW BUTTON
  const handleBuyNowButtonClick = useCallback(() => {
    if (receivedData) {
      console.log('+++++ amtOwedBuyFiat +++++');
      console.log(amtOwedBuyFiat);
      // console.log((parseInt(amtOwedBuyFiat.ethWei, 10) / 10 ** 18).toString());
      console.log(
        (parseInt(amtOwedBuyFiat.usdWeiFull, 10) / 10 ** 6).toString()
      );

      const b_usdNeeded = BigNumber.from(amtOwedBuyFiat.usdWeiFull);
      const b_ethNeeded = BigNumber.from(amtOwedBuyFiat.ethWei);
      console.log('+++++ b_ethNeeded: %s +++++', b_ethNeeded);
      const exchangeETHValue = (
        parseInt(b_ethNeeded.toString(), 10) /
        10 ** 18
      ).toString();
      console.log('+++++ exchangeETHValue: %s +++++', exchangeETHValue);

      // const b_ethNeededNet = b_ethNeeded.sub(BigNumber.from(ethBalance));

      const exchangeUSDPIValue = (
        parseInt(b_usdNeeded.toString(), 10) /
        10 ** 6
      ).toString();
      const ethId = '5f86f8ad-f546-4aeb-b77e-d75733834b39';
      const usdpiId = '5910b23a-0fa6-46f8-ab1c-6e6a71cc0a69';
      const clientId = '9594c88f-2edf-407f-82da-b4ca569690be';
      const url = `https://pay.c14.money/?clientId=${clientId}&targetAssetId=${usdpiId}&targetAmount=${exchangeUSDPIValue}&targetAddress=${receivedData.selectedAddress}&bgColor=282828&mainColor=17171a&quoteAmountLock=true&targetAssetIdLock=true`;
      // const url = `https://pay.c14.money/?clientId=${clientId}&targetAssetId=${ethId}&targetAmount=${exchangeUSDPIValue}&targetAddress=${receivedData.selectedAddress}&bgColor=282828&mainColor=17171a&quoteAmountLock=true&targetAssetIdLock=true`;
      // const url = `https://pay.c14.money/?clientId=${clientId}&targetAssetId=${ethId}&targetAmount=${exchangeUSDPIValue}&targetAddress=${receivedData.selectedAddress}&bgColor=282828&mainColor=17171a&targetAssetIdLock=true`;
      // const url = `https://pay.c14.money/?clientId=${clientId}&targetAssetId=${ethId}&targetAmount=${exchangeETHValue}&targetAddress=${receivedData.selectedAddress}&bgColor=282828&mainColor=17171a&targetAssetIdLock=true`;
      window.open(url, '_blank');
    }
  }, [amtOwedBuyFiat, ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // UPHOLD
  const handleBuyNowUpholdButtonClick = useCallback(() => {
    const url = `https://uphold.com/en-us/assets/crypto/buy-eth`;
    window.open(url, '_blank');
  }, [amtOwedBuyFiat, ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // XCOINS
  const handleBuyNowXCButtonClick = useCallback(() => {
    const url = `https://xcoins.com/en/buy-crypto/ethereum/`;
    window.open(url, '_blank');
  }, [amtOwedBuyFiat, ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // PAYBIS
  const handleBuyNowPBButtonClick = useCallback(() => {
    const url = `https://paybis.com/buy-ethereum/`;
    window.open(url, '_blank');
  }, [amtOwedBuyFiat, ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // COINBASE
  const handleBuyNowCBButtonClick = useCallback(() => {
    const url = `https://login.coinbase.com/signin`;
    window.open(url, '_blank');
  }, [amtOwedBuyFiat, ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleBuyNowButtonClick2 = useCallback(() => {
    console.log('amtOwedBuyFiat');
    console.log(amtOwedBuyFiat);
    console.log((parseInt(amtOwedBuyFiat.ethWei, 10) / 10 ** 18).toString());

    const b_ethNeeded = BigNumber.from(amtOwedBuyFiat.ethWei);
    const b_ethNeededNet = b_ethNeeded.sub(BigNumber.from(ethBalance));

    const exchangeEthValue = (
      parseInt(b_ethNeededNet.toString(), 10) /
      10 ** 18
    ).toString();
    const ethId = '5f86f8ad-f546-4aeb-b77e-d75733834b39';
    if (receivedData) {
      setShowNeed(false);
      setC14Src(
        // `https://pay.c14.money/?targetAssetId=${ethId}&targetAmount=${exchangeEthValue}&targetAddress=${receivedData.selectedAddress}&bgColor=282828&mainColor=17171a&quoteAmountLock=true&targetAssetIdLock=true`
        `https://pay.c14.money/?targetAssetId=${ethId}&targetAmount=${exchangeEthValue}&targetAddress=${receivedData.selectedAddress}&bgColor=282828&mainColor=17171`
      );
      // https://pay.c14.money/?bgColor=282828&mainColor=171719
      setIsC14Open(true);
      // window.open(
      //   `https://pay.c14.money/?targetAssetId=${ethId}&targetAmount=${exchangeEthValue}&targetAddress=${receivedData.selectedAddress}`,
      //   '_blank'
      // );
    }
  }, [amtOwedBuyFiat, ethBalance]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // PREPAY MONTHS CHANGED
  const handleChangePeriod = useCallback(
    (period, percent) => {
      // console.log('+++++ handleChangePeriod +++++');
      // console.log(period);
      setCartPrepayMonths(period);
      setPercentage(percent);
    },
    [setCartPrepayMonths]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const imgGenerate = async () => {
    const cmd =
      'cute, mystic lion, colorful glassy, background stunning, not ugly, up lighting';
    setProcessText('Activating - Generating NFT image.');
    try {
      // throw new Error('This is a test error.');
      const response = await api.post('/v1/image/create', { command: cmd });
      return response.data.image;
    } catch (error) {
      swalError({
        message: 'There was a problem generating an NFT image.',
        textButton: 'Try Again',
      });
      throw error;
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getHashes = async (usdpiNeeded: string) => {
    try {
      if (!receivedData) {
        throw new Error('Wallet address not found');
      } else {
        setProcessText('Activating - Preparing transaction');
        const hashes = await api.get('/v1/member/activate/', {
          params: {
            wallet_address: receivedData.selectedAddress,
            amt2authorize: usdpiNeeded,
          },
        });
        return hashes.data;
      }
    } catch (error) {
      swalError({
        message: 'There was a problem preparing your activation transaction.',
        textButton: 'Try Again',
      });
      throw error;
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleAcceptModalConfirm = useCallback(() => {
    setShowModalConfirm(false);
    if (
      promiseResolveRef.current &&
      typeof promiseResolveRef.current === 'function'
    ) {
      promiseResolveRef.current(undefined); // Pass undefined explicitly
      // Reset the refs after resolving
      promiseResolveRef.current = null;
      promiseRejectRef.current = null;
    }
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleCancelModalConfirm = useCallback(() => {
    // Hide the modal
    setShowModalConfirm(false);

    // Check if there's a reject function and it's callable
    if (
      promiseRejectRef.current &&
      typeof promiseRejectRef.current === 'function'
    ) {
      // Reject the promise with a specific error message
      promiseRejectRef.current(new Error('User cancelled the confirmation'));

      // Reset the promise resolve and reject refs after handling the cancellation
      promiseResolveRef.current = null;
      promiseRejectRef.current = null;
    }
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // CONFIRMATION MODAL WORKFLOW
  const confirmAction = useCallback(() => {
    console.log('+++++ confirmAction running +++++');
    return new Promise((resolve, reject) => {
      // Show the modal
      setShowModalConfirm(true);
      // setPromiseResolve(resolve);
      // setPromiseReject(reject);
      promiseResolveRef.current = resolve;
      promiseRejectRef.current = reject;
    });
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const executeActivation = async (
    profitCenters: any,
    prepayMonths: any,
    hashResultValue: any,
    sig: string,
    imgName: string
  ) => {
    try {
      if (!receivedData) {
        throw new Error('Wallet address not found');
      } else {
        setProcessText('Activating - Sending activation.');
        try {
          const acctresponse = await api.post('/v1/member/activate', {
            owner: receivedData.selectedAddress,
            prepay: prepayMonths,
            pcount: profitCenters,
            amt: hashResultValue.amt2authorize,
            deadline: hashResultValue.deadline,
            sig,
            nft_id: null,
            img_name: imgName,
          });
          console.log(acctresponse);
          return acctresponse.data.tx;
        } catch (error) {
          console.log('================= ERROR ===================');
          console.log(error);
          throw error;
        }
      }
    } catch (error) {
      swalError({
        message: 'There was a problem activating your account.',
        textButton: 'Try Again',
      });
      throw error;
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const getSignature = async (hashData: any) => {
    try {
      if (!receivedData) {
        throw new Error('Wallet address not found');
      } else {
        setProcessText('Activating - Waiting for wallet signature.');
        const { deadline, amt2authorize, nonce, name, chain } = hashData;
        const version = '1';
        const domainData = {
          name,
          version,
          chainId: chain,
          verifyingContract: process.env.REACT_APP_BSC_USDPI,
        };
        const types = {
          Permit: [
            {
              name: 'owner',
              type: 'address',
            },
            {
              name: 'spender',
              type: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
            },
            {
              name: 'nonce',
              type: 'uint256',
            },
            {
              name: 'deadline',
              type: 'uint256',
            },
          ],
        };
        const val = {
          owner: receivedData.selectedAddress,
          spender: process.env.REACT_APP_BSC_USDPISUBS,
          // spender: process.env.REACT_APP_BSC_MEMBEROPS,
          value: amt2authorize,
          nonce,
          deadline,
        };
        const tprovider = new ethers.providers.Web3Provider(window.ethereum);
        const tsigner = tprovider.getSigner();
        const sig = await tsigner._signTypedData(domainData, types, val); // eslint-disable-line no-underscore-dangle
        return sig;
      }
    } catch (error) {
      swalError({
        message: 'There was a problem Getting your signature.',
        textButton: 'Try Again',
      });
      throw error;
    }
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const logExchange = useCallback((hash: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      console.log('+++++ logExchange +++++');
      api
        .post('/v1/usdpi/deposit/usdt/tx', {
          nft_id: 0,
          tx: hash,
        })
        .then(function (acctresponse) {
          setProcessText(
            'Exchange accepted. Waiting for bridged USDPI to arrive.'
          );
          resolve('Complete');
        })
        .catch(function (error) {
          console.log('== ERROR ==');
          console.log(error);
          reject(error);
        });
    });
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const exchange = useCallback(
    (amtOwed: AmtNeeded, profitCenters): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (receivedData) {
          networkSwitch('PAY')
            .then((response) => {
              setLoading(true);
              try {
                // console.log(amtOwed);
                // console.log(profitCenters);
                // Call exchange
                const tprovider = new ethers.providers.Web3Provider(
                  window.ethereum
                );
                const tsigner = tprovider.getSigner();
                const swapBank = new ethers.Contract(
                  process.env.REACT_APP_ETH_SWAP as string,
                  EthUsdtSwapV3Abi,
                  tprovider
                );
                console.log('amtOwed.ethWei: %s', amtOwed.ethWei);
                console.log('amtOwed.usdWeiFull: %s', amtOwed.usdWeiFull);
                swapBank
                  .connect(tsigner)
                  .swapEth4Usdt(amtOwed.ethWei, 0, {
                    value: amtOwed.ethWei,
                  })
                  // .swapEth4Usdt('1000000000000000', 0, {
                  //   value: '1000000000000000',
                  // })
                  .then((tx: ContractTransaction) => {
                    // console.log('+++++ ContractTransaction +++++');
                    // console.log(tx);
                    setProcessText(
                      'Exchange - waiting for transaction to complete'
                    );
                    console.log('Transaction sent successfully:', tx);

                    // Start polling for the transaction receipt
                    const interval = 10000; // Check every 10 seconds
                    const timeout = 300000; // Stop checking after 5 minutes
                    const intervalId = setInterval(async () => {
                      try {
                        console.log('+++++ Getting receipt +++++');
                        const receipt =
                          await tsigner.provider.getTransactionReceipt(tx.hash);
                        if (receipt && receipt.confirmations > 0) {
                          console.log(receipt);
                          clearInterval(intervalId);
                          setProcessText('Exchange - bridging funds');
                          logExchange(tx.hash)
                            .then((logResult) => {
                              setTimeout(() => {
                                resolve('Complete');
                              }, 10000);
                            })
                            .catch((logError) => {
                              reject(logError);
                            });
                        }
                      } catch (error) {
                        console.error(
                          'Error while checking transaction status:',
                          error
                        );
                      }
                    }, interval);

                    // Set a timeout to stop checking
                    setTimeout(() => {
                      clearInterval(intervalId);
                      // Handle the case where the transaction is not mined within the timeout
                      console.error(
                        'Transaction was not mined within the specified timeout.'
                      );
                      logExchange(tx.hash)
                        .then((logResult) => {
                          resolve('Complete');
                        })
                        .catch((logError) => {
                          reject(logError);
                        });
                    }, timeout);
                  })
                  .catch((error: Error) => {
                    console.error('Error during swap:', error);
                    reject(error);
                  });
                // ------------------------
              } catch (err) {
                reject(err);
                // setIsAlertOpen(true);
              }
            })
            .catch((switchError) => {
              console.log(switchError);
              reject(switchError);
            });
        }
      });
    },
    [receivedData]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const activate = useCallback(
    (usdpiNeeded, profitCenters): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (receivedData) {
          confirmAction()
            .then(() => {
              setLoading(true);
              networkSwitch('NETWORK')
                .then((response) => {
                  imgGenerate()
                    .then((imgResultValue) => {
                      getHashes(usdpiNeeded)
                        .then((hashResultValue) => {
                          console.log(hashResultValue);
                          getSignature(hashResultValue)
                            .then((sig) => {
                              executeActivation(
                                profitCenters,
                                cartPrepayMonths,
                                hashResultValue,
                                sig,
                                imgResultValue
                              )
                                .then((activationResult) => {
                                  console.log(
                                    'activationResult: %s',
                                    activationResult
                                  );
                                  setLoading(false);
                                  setAccount(receivedData.selectedAddress);
                                  // history.push({
                                  //   pathname: '/',
                                  // });
                                })
                                .catch((error) => {
                                  console.log('activation error: %s', error);
                                  setLoading(false);
                                });
                            })
                            .catch((error) => {
                              console.log('signature error: %s', error);
                              setLoading(false);
                            });
                        })
                        .catch((error) => {
                          // xxx
                          console.log('hashes error: %s', error);
                          setLoading(false);
                        });
                    })
                    .catch((error) => {
                      // xxx
                      console.log('img error: %s', error);
                      setLoading(false);
                    });
                })
                .catch((switchError) => {
                  console.log(switchError);
                  reject(switchError);
                });
            })
            .catch(() => {
              // Handle the case where the user cancels the confirmation
              console.log('User cancelled the activation');
              setLoading(false);
            });
        }
      });
    },
    [receivedData, cartPrepayMonths, setCartPrepayMonths]
  );

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // EXCHANGE POST POLLING
  // When activating and usdpi balance changes,
  // proceed if balance gt amtNeeded
  useEffect(() => {
    // console.log('++++++++++++++++++++++++++++++++++++++');
    // console.log('++++++++++++++++++++++++++++++++++++++');
    // console.log('usdpiBalance: %s', usdpiBalance);
    // console.log('lastUsdpiBalance: %s', lastUsdpiBalance);
    // console.log('amtNeededActivate: %s', amtNeededActivate);
    // console.log('++++++++++++++++++++++++++++++++++++++');
    // console.log('++++++++++++++++++++++++++++++++++++++');
    if (amtNeededActivate && profitCentersNeededActivate) {
      if (!pollingEnabled) {
        console.log('Start polling');
        setPollingEnabled(true);
      }
      console.log('Activation checks running');
      console.log(
        'profitCentersNeededActivate: %s',
        profitCentersNeededActivate
      );
      console.log('usdpiBalance: %s', usdpiBalance);
      console.log('lastUsdpiBalance: %s', lastUsdpiBalance);
      console.log('amtNeededActivate: %s', amtNeededActivate);
      if (usdpiBalance !== lastUsdpiBalance) {
        console.log('Balance change detected');
        if (
          // true // ToDo: TEST - remove for prod
          ethers.BigNumber.from(usdpiBalance).gte(
            ethers.BigNumber.from(amtNeededActivate)
          )
        ) {
          console.log('Enough USDPI - ACTIVATE');
          setProcessText('Activating - Waiting for wallet approval.');
          activate(amtNeededActivate, profitCentersNeededActivate)
            .then((resultValue) => {
              console.log('activate result: %s', resultValue);
              setLoading(false);
            })
            .catch((error) => {
              console.log('activate error: %s', error);
              setLoading(false);
            });
        } else {
          console.log('Not enough balance');
          setLoading(false);
          swalError({
            message:
              'Sorry, your USDPI has arrived, but your balance is too small to activate.',
            textButton: 'Try Again',
          });
          setAmtNeededActivate(null);
          setProfitCentersNeededActivate(null);
        }
      } else {
        console.log('No balance change...yet');
      }
    } else {
      console.log('No activation checks running');
    }
  }, [
    usdpiBalance,
    amtNeededActivate,
    profitCentersNeededActivate,
    lastUsdpiBalance,
    pollingEnabled,
  ]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const fetchBalances = async () => {
    try {
      if (receivedData) {
        const response = await api.get(
          `v2/member/tokenBalances/${receivedData.selectedAddress}`
        );
        if (response.data.balances.wallet_eth !== ethBalance) {
          setEthBalance(response.data.balances.wallet_eth);
        }
        if (response.data.balances.wallet_usdpi !== usdpiBalance) {
          setUsdpiBalance(response.data.balances.wallet_usdpi);
        }
        return { balances: response.data.balances };
      }
    } catch (error) {
      console.log(error);
      setEthBalance('0');
      setUsdpiBalance('0');
      setLoading(false);
      throw error;
    }
    return { balances: {} };
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // BALANCE POLLING
  useEffect(() => {
    // Initial fetch
    fetchBalances();

    // POLL BALANCES
    if (pollingEnabled) {
      const interval = setInterval(() => {
        fetchBalances();
      }, 30000);
      // Cleanup function: Clear the interval when the component unloads
      return () => {
        clearInterval(interval);
      };
    }
    return undefined;
  }, [pollingEnabled]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // PAY NOW button handler.
  // Received amtOwed structure from left or right form
  const handleBuyNowClick = useCallback(
    (amtOwed: AmtNeeded, profitCenters) => {
      console.log('+++++ amtOwed +++++');
      if (receivedData) {
        setProcessText('Getting wallet balances');
        // setLoading(true);
        // ====================================
        // ====================================
        // TEST ONLY - REMOVE FOR PROD
        // (adjust to smaller minimum eth needed)
        // ====================================
        // console.log(amtOwed);
        // const ethWeiTEST = '1000000000000000';
        // setAmtNeededActivate('2000000');
        // ====================================
        // END TEST ONLY - REMOVE FOR PROD
        // ====================================
        // ====================================

        getQuote(amtOwed.usdWeiFull)
          .then((ethWei) => {
            console.log('+++++ new ethWei: %s +++++', ethWei);
            // GET BALANCES
            fetchBalances()
              .then((balances) => {
                // console.log(balances);
                setLastUsdpiBalance(usdpiBalance);
                // SET CONSTANTS
                const b_usdNeeded: BigNumber = ethers.BigNumber.from(
                  amtOwed.usdWei
                );
                const b_usdpiNeeded: BigNumber = b_usdNeeded.mul(
                  BigNumber.from(10).pow(12)
                );
                // const b_usdpiNeeded_test = b_usdpiNeeded.add(
                //   BigNumber.from('9000000000000000000')
                // );
                const b_ethNeeded: BigNumber = ethers.BigNumber.from(
                  ethWei
                  // ethWeiTEST // TEST ONLY - REMOVE FOR PROD
                );
                const b_usdpiBal: BigNumber = ethers.BigNumber.from(
                  balances.balances.wallet_usdpi
                );
                const b_ethBal = ethers.BigNumber.from(
                  balances.balances.wallet_eth
                );
                // ====================================
                // VALIDATE AMOUNTS
                // ====================================
                // TEST STUB
                // ====================================
                // console.log('+++ eth balance: %s', b_ethBal);
                // console.log('+++ eth needed: %s', b_ethNeeded);
                // if (false) {
                //   setProcessText('Activating - Waiting for wallet approval.');

                //   activate(b_usdpiNeeded.toString(), profitCenters)
                //     .then((resultValue) => {
                //       console.log('activate result: %s', resultValue);
                //       setLoading(false);
                //     })
                //     .catch((error) => {
                //       console.log('activate error: %s', error);
                //       setLoading(false);
                //     });
                // }
                // ====================================
                // END TEST STUB
                // ====================================
                if (b_usdpiBal.isZero() && b_ethBal.isZero()) {
                  // console.log('amtOwed');
                  // console.log(amtOwed);
                  setAmtOwedBuyFiat({ ...amtOwed });
                  setLoading(false);
                  setShowNeed(true);
                  // } else if (b_usdpiBal.gte(b_usdpiNeeded_test)) {
                } else if (b_usdpiBal.gte(b_usdpiNeeded)) {
                  // Enough USDPI - ACTIVATE!!!
                  console.log('Enough USDPI - ACTIVATE');
                  console.log('b_usdpiNeeded: %s', b_usdpiNeeded);
                  setProcessText('Activating - Waiting for wallet approval.');
                  activate(b_usdpiNeeded.toString(), profitCenters)
                    .then((resultValue) => {
                      console.log('activate result: %s', resultValue);
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.log('activate error: %s', error);
                      setLoading(false);
                    });
                } else if (b_ethBal.gte(b_ethNeeded) && !b_ethNeeded.isZero()) {
                  console.log('No USDPI, but have ETH - EXCHANGE!!!');
                  // No USDPI, but have ETH - EXCHANGE!!!
                  setProcessText(
                    'Exchanging ETH for USDPI - Waiting for wallet approval.'
                  );
                  setProfitCentersNeededActivate(profitCenters);
                  exchange(amtOwed, profitCenters)
                    .then((resultValue) => {
                      console.log('exchange result: %s', resultValue);
                      // const bn_amtNeededActivate = BigNumber.from(
                      //   amtOwed.usdWeiFull
                      // ).mul(BigNumber.from(10).pow(12));
                      const bn_amtNeededActivate = BigNumber.from(
                        amtOwed.usdWei
                      ).mul(BigNumber.from(10).pow(12));
                      // .add(BigNumber.from(5).mul(BigNumber.from(10).pow(18)));
                      setAmtNeededActivate(bn_amtNeededActivate.toString());
                    })
                    .catch((error) => {
                      console.log('exchange error: %s', error);
                      setLoading(false);
                    });
                } else {
                  console.log('Not enough crypto');
                  setAmtOwedBuyFiat({ ...amtOwed });
                  setLoading(false);
                  setShowNeed(true);
                }
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
                swalError({
                  message: 'There was a problem getting your wallet balances.',
                  textButton: 'Try Again',
                });
              });
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              console.log('Request canceled:', error.message);
            } else {
              // Handle other errors
            }
          });
      } // if (receivedData)
    },
    [receivedData, usdpiBalance, cartPrepayMonths, amtOwedBuyFiat]
  );

  return (
    <>
      <Banner className="position-relative overflow-hidden w-100">
        <div className="container-fluid">
          <div className="row vh-lg-100 overflow-auto justify-content-center">
            <div className="d-none col-12 d-lg-flex justify-content-between wallet-avatar">
              <WalletRowAutoAffiliate
                ethBalance={ethBalance}
                usdpiBalance={usdpiBalance}
              />
              {receivedData && (
                <WalletAvatar
                  selectedAddress={receivedData.selectedAddress || ''}
                  refCode={receivedData.reference}
                  placement={receivedData.placementRef}
                  username={receivedData.username}
                  sponsorName={receivedData.sponsorName}
                  sponsorUsername={receivedData.sponsorUsername}
                />
              )}
            </div>

            <div className="col-lg-10 col-xl-9 py-lg-4 zoom-order">
              <div className="row justify-content-center px-xl-5 mb-4 transition">
                <div className="col-xl-10 text-center d-flex justify-content-center">
                  <div className="w-100">
                    <h1 className="fw-bold">
                      <span>
                        <span>Select Membership Option</span>
                      </span>
                    </h1>
                  </div>
                </div>
              </div>

              <div className="row overflow-small justify-content-sm-center mb-4 mb-xxl-5">
                <div className="col-12 d-flex justify-content-center btn-percentage">
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(12, 200)}
                    className={`${
                      percentage === 200 && 'active'
                    } mx-2 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto">
                      Yearly
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(6, 175)}
                    className={`${
                      percentage === 175 && 'active'
                    } mx-2 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto">
                      6&nbsp;Months
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(3, 150)}
                    className={`${
                      percentage === 150 && 'active'
                    } mx-2 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto">
                      3&nbsp;Months
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangePeriod(1, 133)}
                    className={`${
                      percentage === 133 && 'active'
                    } mx-2 border-0 rounded-pill`}
                  >
                    <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto">
                      Monthly
                    </span>
                  </button>
                </div>
              </div>

              <div className="row justify-content-center pb-3 pb-lg-0 px-sm-5 px-xl-2 px-xxl-5 transition">
                <Card className="col-sm-11 col-md-7 col-lg-6 mt-3 mt-lg-0 px-4 mb-5 mb-lg-0">
                  <div className="h-100 card-price max-wid best-price ms-auto">
                    <div className="p-3 w-100">
                      <h2 className="h3 text-center text-white my-4">
                        {cartProfitCentersLeft} PROFIT CENTER
                        {cartProfitCentersLeft > 1 && 'S'}
                      </h2>

                      <div className="d-flex text-center value-token position-relative text-center justify-content-center">
                        <p className="mb-3 d-flex align-items-center">
                          <span className="pe-3">$</span>
                          {formatUSDValueFromWei(amtOwedLeft.usdWei).slice(
                            0,
                            -3
                          )}
                        </p>
                      </div>
                      <div className="d-flex text-center position-relative text-center justify-content-center mb-3">
                        <div className="eth-amt-wrap d-flex text-center position-relative text-center justify-content-center">
                          <div className="d-flex w-100 inner">
                            <div className="d-flex align-items-center justify-content-between w-100 txt">
                              <img src={ethLogo} alt="Logo" />
                              <span className="d-flex mx-auto">
                                {formattedEthValue}&nbsp;ETH
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mb-3">
                        <input
                          type="range"
                          name="teste"
                          min={1}
                          max={6}
                          onChange={(e) => {
                            setCartProfitCentersLeft(
                              parseInt(e.target.value, 10)
                            );
                          }}
                          value={cartProfitCentersLeft}
                          className="slider-pic"
                        />
                        <span className="thumb" />
                        <span className="py-0 text-white h3 mb-0">
                          &nbsp;{cartProfitCentersLeft}
                        </span>
                      </div>
                      <p className="text-center amount mb-4">
                        Use slider to change amount
                      </p>
                      <p className="earnings-title mb-0">
                        Monthly Earnings Potential of:
                      </p>
                      <div className="value">
                        <p className="mb-0">$1,054,650</p>
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="btn-pay w-100 mt-2 fw-bold"
                          onClick={() =>
                            handleBuyNowClick(
                              amtOwedLeft,
                              cartProfitCentersLeft
                            )
                          }
                        >
                          <span className="">PAY NOW</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="col-sm-11 col-md-7 col-lg-6 mt-3 mt-lg-0 px-4 mb-5 mb-lg-0">
                  <div className="h-100 card-price-gray max-wid best-price me-auto">
                    <div className="p-3 w-100">
                      <h2 className="h3 text-center text-white my-4">
                        {cartProfitCentersRight} PROFIT CENTER
                        {cartProfitCentersRight > 1 && 'S'}
                      </h2>

                      <div className="d-flex text-center value-token position-relative text-center justify-content-center">
                        <p className="mb-3 d-flex align-items-center">
                          <span className="pe-3">$</span>
                          {formatUSDValueFromWei(amtOwedRight.usdWei).slice(
                            0,
                            -3
                          )}
                        </p>
                      </div>
                      <div className="d-flex text-center position-relative text-center justify-content-center mb-3">
                        <div className="eth-amt-wrap d-flex text-center position-relative text-center justify-content-center">
                          <div className="d-flex w-100 inner">
                            <div className="d-flex align-items-center justify-content-between w-100 txt">
                              <img src={ethLogo} alt="Logo" />
                              <span className="d-flex mx-auto">
                                {formattedEthValueRight}&nbsp;ETH
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mb-3">
                        <input
                          type="range"
                          name="teste"
                          min={1}
                          max={6}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setCartProfitCentersRight(
                              parseInt(e.target.value, 10)
                            );
                          }}
                          value={cartProfitCentersRight}
                          className="slider-pic"
                        />
                        <span className="thumb" />
                        <span className="py-0 text-white h3 mb-0">
                          &nbsp;{cartProfitCentersRight}
                        </span>
                      </div>
                      <p className="text-center amount mb-4">
                        Use slider to change amount
                      </p>
                      <p className="earnings-title mb-0">
                        Monthly Earnings Potential of:
                      </p>
                      <div className="value">
                        <p className="mb-0">$175,775</p>
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="btn-pay-gray w-100 mt-2 fw-bold"
                          onClick={() =>
                            handleBuyNowClick(
                              amtOwedRight,
                              cartProfitCentersRight
                            )
                          }
                        >
                          <span className="">PAY NOW</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="col-sm-11 col-md-7 col-lg-11 text-center px-1">
                  <button
                    type="button"
                    className="btn-calculator w-100 mt-4 fw-bold"
                    onClick={handleShow}
                  >
                    <span className="">OPEN EARNINGS CALCULATOR</span>
                  </button>
                </div>
                {/* TEMP TEST */}
                {/* <div className="">
                  <button
                    type="button"
                    className="btn-pay w-100 mt-2 fw-bold"
                    onClick={() => setPollingEnabled(!pollingEnabled)}
                  >
                    <span className="">TOGGLE POLLING</span>
                  </button>
                </div> */}
                {/* TEMP TEST */}
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          className="modal-signin"
          centered
        >
          <button
            type="button"
            className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>

          <Modal.Header className="container py-0 border-0">
            <div className="row w-100 justify-content-center">
              <div className="col-12 text-center">
                <h2 className="fw-bold mb-0">Earnings Potential</h2>
              </div>
            </div>
          </Modal.Header>

          <Modal.Body className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-6 btn-calculator text-center mt-4">
                <button
                  type="button"
                  className={`${
                    btnYearlyMonthly === 'yearly' && 'active'
                  } mx-3 border-0 rounded-pill`}
                  onClick={() => handleBtnYearly('yearly')}
                >
                  <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                    Yearly
                  </span>
                </button>
                <button
                  type="button"
                  className={`${
                    btnYearlyMonthly === 'monthly' && 'active'
                  } mx-3 border-0 rounded-pill`}
                  onClick={() => handleBtnYearly('monthly')}
                >
                  <span className="rounded-pill d-block px-lg-2 px-xl-4 px-xxl-auto py-2">
                    Monthly
                  </span>
                </button>
              </div>
            </div>
            {btnYearlyMonthly === 'yearly' && (
              <div className="row px-4 px-lg-5 mt-5 justify-content-center">
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">1. Profit Centers</h3>
                      <RangeCentersYearly
                        percentage={
                          percentageBarCenterYearly >= 4
                            ? (percentageBarCenterYearly - 1) * 19.7
                            : (percentageBarCenterYearly - 1) * 20.2
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={1}
                            max={6}
                            value={percentageBarCenterYearly}
                            onChange={(e) =>
                              setPercentageBarCenterYearly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarCenterYearly > 1 && (
                            <div
                              className="progress-bar-centers"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeCentersYearly>

                      <div className="values-total-members d-flex justify-content-between px-2 mt-3">
                        <span className="">1</span>
                        <span className="">2</span>
                        <span className="">3</span>
                        <span className="">4</span>
                        <span className="">5</span>
                        <span className="">6</span>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Subscription Cost
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">
                            $
                            {formatPrice(
                              percentageBarCenterYearly * 50 * 12
                            ).slice(0, -3)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">2. Network Size</h3>
                      <RangeNetworkYearly
                        percentage={
                          percentageBarCenterYearly === 1
                            ? percentageBarNetworkYearly * 20
                            : percentageBarNetworkYearly * 16.7
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={0}
                            max={percentageBarCenterYearly === 1 ? 5 : 6}
                            value={percentageBarNetworkYearly}
                            onChange={(e) =>
                              setPercentageBarNetworkYearly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarNetworkYearly > 0 && (
                            <div
                              className="progress-bar-network"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeNetworkYearly>

                      <div
                        className={`${
                          percentageBarCenterYearly === 1 &&
                          'justify-content-between'
                        } network values-total-members ps-2 d-flex mt-3`}
                      >
                        <span className="">5</span>
                        <span className="">30</span>
                        <span className="">155</span>
                        <span className="">780</span>
                        <span className="">3,905</span>
                        <span className="">19,530</span>
                        {percentageBarCenterYearly > 1 && (
                          <span className="">
                            {formatPrice(
                              19530 + (percentageBarCenterYearly - 1) * 15625
                            ).replace('.00', '')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Earnings
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">{demoEarningPrice}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0 mt-lg-5">
                  <div className="row bg-number py-3 justify-content-center">
                    <div className="col-lg-8">
                      <div className="row justify-content-evenly align-items-center">
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block cost mb-1 mx-auto">
                            Your Cost
                          </span>
                          <span className="d-block value-cost">
                            $
                            {formatPrice(
                              percentageBarCenterYearly * 50 * 12
                            ).slice(0, -3)}
                          </span>
                        </div>
                        <div className="col-3 col-lg-4 px-0 px-lg-3 pe-none text-center">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={140}
                          />
                        </div>
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block earning mb-1 mx-auto">
                            Your Earnings
                          </span>
                          <span className="d-block value-earnings">
                            {demoEarningPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {btnYearlyMonthly === 'monthly' && (
              <div className="row px-4 px-lg-5 mt-5 justify-content-center">
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">1. Profit Centers</h3>
                      <RangeCentersMonthly
                        percentage={
                          percentageBarCenterMonthly >= 4
                            ? (percentageBarCenterMonthly - 1) * 19.7
                            : (percentageBarCenterMonthly - 1) * 20.2
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={1}
                            max={6}
                            value={percentageBarCenterMonthly}
                            onChange={(e) =>
                              setPercentageBarCenterMonthly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarCenterMonthly > 1 && (
                            <div
                              className="progress-bar-centers"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeCentersMonthly>

                      <div className="values-total-members d-flex justify-content-between px-2 mt-3">
                        <span className="">1</span>
                        <span className="">2</span>
                        <span className="">3</span>
                        <span className="">4</span>
                        <span className="">5</span>
                        <span className="">6</span>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Subscription Cost
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">
                            $
                            {formatPrice(percentageBarCenterMonthly * 50).slice(
                              0,
                              -3
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-xl-8 mb-4">
                  <div className="row">
                    <div className="col-lg-9">
                      <h3 className="mb-3">2. Network Size</h3>
                      <RangeNetworkMonthly
                        percentage={
                          percentageBarCenterMonthly === 1
                            ? percentageBarNetworkMonthly * 20
                            : percentageBarNetworkMonthly * 16.7
                        }
                        className="position-relative"
                      >
                        <>
                          <input
                            type="range"
                            name="teste"
                            min={0}
                            max={percentageBarCenterMonthly === 1 ? 5 : 6}
                            value={percentageBarNetworkMonthly}
                            onChange={(e) =>
                              setPercentageBarNetworkMonthly(
                                parseInt(e.target.value, 10)
                              )
                            }
                            className="slider-progress-bar"
                          />
                          <span className="thumb-progress-bar" />
                          {percentageBarNetworkMonthly > 0 && (
                            <div
                              className="progress-bar-network"
                              role="progressbar"
                            />
                          )}
                        </>
                      </RangeNetworkMonthly>

                      <div
                        className={`${
                          percentageBarCenterMonthly === 1 &&
                          'justify-content-between'
                        } network values-total-members ps-2 d-flex mt-3`}
                      >
                        <span className="">5</span>
                        <span className="">30</span>
                        <span className="">155</span>
                        <span className="">780</span>
                        <span className="">3,905</span>
                        <span className="">19,530</span>
                        {percentageBarCenterMonthly > 1 && (
                          <span className="">
                            {formatPrice(
                              19530 + (percentageBarCenterMonthly - 1) * 15625
                            ).replace('.00', '')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center d-lg-block">
                      <h6 className="mb-0 w-50 w-lg-100 text-lg-center mt-lg-2">
                        Earnings
                      </h6>
                      <div className="bg-value-revenue w-50 w-lg-100 d-flex align-items-center justify-content-center">
                        <div className="value-revenue w-90 justify-content-center text-center d-flex align-items-center">
                          <span className="mx-3">{demoEarningPrice}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0 mt-lg-5">
                  <div className="row bg-number py-3 justify-content-center">
                    <div className="col-lg-8">
                      <div className="row justify-content-evenly align-items-center">
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block cost mb-1 mx-auto">
                            Your Cost
                          </span>
                          <span className="d-block value-cost">
                            $
                            {formatPrice(percentageBarCenterMonthly * 50).slice(
                              0,
                              -3
                            )}
                          </span>
                        </div>
                        <div className="col-3 col-lg-4 px-0 px-lg-3 pe-none text-center">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={140}
                          />
                        </div>
                        <div className="col-4 text-center ps-lg-4">
                          <span className="d-block earning mb-1 mx-auto">
                            Your Earnings
                          </span>
                          <span className="d-block value-earnings">
                            {demoEarningPrice}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 px-4" />
        </Modal>

        <ModalNeed
          size="lg"
          show={showNeed}
          onHide={handleClose}
          className="modal-signin-auto"
          centered
        >
          <button
            type="button"
            className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>
          <Modal.Header className="justify-content-center border-0 pb-0 pt-5">
            <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden pe-none">
              <Lottie
                options={{
                  animationData: stakingTerms,
                  autoplay: true,
                  loop: true,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={157}
                width={157}
              />
            </div>
          </Modal.Header>
          <Modal.Body className="px-4 px-sm-5">
            <h2 className="mb-2 fw-bold text-center w-100">Need Funds?</h2>
            <div className="texts w-100 px-3">
              <p className="text-center mb-4">
                We could not find ETH, BNB or USPI in your wallet
              </p>
              <div className="d-flex btns-coin justify-content-between">
                <button
                  type="button"
                  className={`${selectCoin === 'usdpi' && 'active'}`}
                  onClick={() => setSelectCoin('usdpi')}
                >
                  <img src={usdpiLogo} alt="USDPI" /> USDPI
                </button>
                <button
                  type="button"
                  className={`${selectCoin === 'usdc' && 'active'}`}
                  onClick={() => setSelectCoin('usdc')}
                >
                  <img src={usdcLogo} alt="USDC" /> USDC
                </button>
                <button
                  type="button"
                  className={`${selectCoin === 'eth' && 'active'}`}
                  onClick={() => setSelectCoin('eth')}
                >
                  <img src={ethLogoWhite} alt="ETH" /> ETH
                </button>
                <button
                  type="button"
                  className={`${selectCoin === 'bnb' && 'active'}`}
                  onClick={() => setSelectCoin('bnb')}
                >
                  <img src={bnbLogo} alt="BNB" /> BNB
                </button>
              </div>
              <p className="my-4">
                <span>Buy at least</span> 0.235 {selectCoin.toUpperCase()}
              </p>
            </div>
            <div className="w-lg-90 mx-auto">
              <p className="text-worry text-center mb-4">
                Don’t worry, you can buy it instantly at the{' '}
                <span className="fw-semibold">options&nbsp;below:</span>
              </p>
              {selectCoin === 'usdpi' && (
                <div>
                  <button
                    type="button"
                    className="btn-c14 w-100 my-1"
                    onClick={handleBuyNowButtonClick}
                  >
                    BUY USDPI NOW AT C14{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#000"
                      size={28}
                    />
                  </button>
                </div>
              )}
              {selectCoin === 'usdc' && (
                <div>
                  <button type="button" className="btn-c14 w-100 my-1">
                    BUY USDC NOW AT C14{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#000"
                      size={28}
                    />
                  </button>
                  <button type="button" className="btn-paybis w-100 my-1">
                    BUY USDC NOW AT PAYBIS{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                  <button type="button" className="btn-xcoins w-100 my-1">
                    BUY USDC NOW AT XCOINS{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                  <button type="button" className="btn-uphold w-100 my-1">
                    BUY USDC NOW AT UPHOLD{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#000"
                      size={28}
                    />
                  </button>
                </div>
              )}
              {selectCoin === 'eth' && (
                <div>
                  <button
                    type="button"
                    className="btn-uphold w-100 my-1"
                    onClick={handleBuyNowUpholdButtonClick}
                  >
                    BUY ETH NOW AT UPHOLD{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#000"
                      size={28}
                    />
                  </button>
                  <button type="button" className="btn-xcoins w-100 my-1">
                    BUY ETH NOW AT COINBASE{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                </div>
              )}
              {selectCoin === 'bnb' && (
                <div>
                  <button type="button" className="btn-c14 w-100 my-1">
                    BUY BNB NOW AT C14{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#000"
                      size={28}
                    />
                  </button>

                  <button type="button" className="btn-xcoins w-100 my-1">
                    BUY BNB NOW AT COINBASE{' '}
                    <HiArrowNarrowRight
                      className="ms-3"
                      color="#fff"
                      size={28}
                    />
                  </button>
                </div>
              )}
              <button
                type="button"
                onClick={handleClose}
                className="btn-cancel w-100 my-1"
              >
                <span className="">Cancel</span>
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 py-4" />
        </ModalNeed>
        <ModalAmount
          size="lg"
          show={showAmount}
          onHide={handleClose}
          className="modal-signin-auto"
          centered
        >
          <button
            type="button"
            className="h4 modal-close m-2 mb-0 ms-auto border-0 bg-transparent"
            onClick={handleClose}
          >
            x
          </button>
          <Modal.Header className="justify-content-center border-0 pb-0 pt-5" />
          <Modal.Body className="px-4 px-sm-5">
            <h2 className="mb-4 fw-bold text-center w-100">Select Amount</h2>
            <div className="texts w-100 px-3">
              <div className="bg-coin">
                <span>
                  <img src={ethLogo} alt="Coin" className="me-3" />
                  ETH
                </span>
              </div>
              <div className="bg-transparent">
                <div className="bg-eth">
                  <div className="d-flex align-items-center justify-content-between">
                    <button type="button" className="d-flex align-items-center">
                      <img src={usdLogo} alt="Usd" className="me-2" /> USD{' '}
                      <img src={arrow} alt="Arrow" className="ms-2" />
                    </button>
                    <div>
                      <span className="value-eth">
                        1 ETH = <span> $1,876.88 USD</span>
                      </span>
                      <img src={ethLogo} alt="Logo" className="ms-2" />
                    </div>
                  </div>
                  <span className="value">{valueSelected}.00</span>
                </div>
              </div>
              <div className="bg-btns-value d-flex">
                <button
                  type="button"
                  onClick={() => setValueSelected(100)}
                  className={`${
                    valueSelected === 100 ? 'btn-active' : 'btn-not-active'
                  }`}
                >
                  $100
                </button>
                <button
                  type="button"
                  onClick={() => setValueSelected(250)}
                  className={`${
                    valueSelected === 250 ? 'btn-active' : 'btn-not-active'
                  }`}
                >
                  $250
                </button>
                <button
                  type="button"
                  onClick={() => setValueSelected(500)}
                  className={`${
                    valueSelected === 500 ? 'btn-active' : 'btn-not-active'
                  }`}
                >
                  $500
                </button>
              </div>
              <button type="button" className="btn-confirm w-100 mt-4">
                Continue{' '}
                <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 py-4" />
        </ModalAmount>
        <ModalC14
          isOpen={isC14Open}
          onRequestClose={closeC14Modal}
          iframeSrc={c14Src || undefined}
        />
        {receivedData && (
          <ModalPleaseConfirm
            handleCancel={handleCancelModalConfirm}
            handleAccept={handleAcceptModalConfirm}
            show={showModalConfirm}
            aaName={receivedData.sponsorName}
          />
        )}
        <Loading
          type="dark"
          srcImg={logoWhite}
          text="xxx"
          className="zoom-1-3"
          active={loading}
        />
      </Banner>
    </>
  );
};

export default OrderOption;
