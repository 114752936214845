import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import { useLocation } from 'react-router-dom';
import api from '../services/api';

import { formatPrice } from '~/utils/format';
import { web3store } from '~/store';

interface IUser {
  id: string;
  screen_name: string;
  prfti_balance: string;
  usdpi_balance: string;
  nft_img: string;
  nft_referrals: {
    current: number;
    new: number;
  };
  matrix_referrals: {
    current: number;
    new: number;
  };
  referral_link: string;
  earned: number;
  aa_username: string;
}
interface AuthContextData {
  connected: boolean;
  setConnected: React.Dispatch<React.SetStateAction<boolean>>;
  status: string;
  user: IUser | undefined;
  signOut(): void;
  setStatus(statusData: string): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [status, setStatus] = useState(() => {
    const statusData = localStorage.getItem('@PROFITi:status');

    return statusData || '';
  });
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [connected, setConnected] = useState(false);
  const [connectedMember, setConnectedMember] =
    web3store.useState('connectedMember');
  const [, setLastPageServed] = web3store.useState('lastPageServed');
  const [, setAccount] = web3store.useState('account');

  useEffect(() => {
    // localStorage.setItem('@PROFITi:status', 'member');
    // setConnected(true);
    if (connectedMember && connectedMember.nft.is_active) {
      const url = '/v1/member/dashboard/';
      api
        .get(url.concat(connectedMember.memberId))
        .then((response) => {
          const earned = formatPrice(
            parseFloat(
              (parseInt(response.data.member.earned, 10) / 10 ** 18).toFixed(2)
            )
          );
          const prfti_balance = (
            parseInt(response.data.member.prfti_balance, 10) /
            10 ** 18
          ).toFixed(2);
          const usdpi_balance = (
            parseInt(response.data.member.usdpi_balance, 10) /
            10 ** 18
          ).toFixed(2);
          setUser({
            ...response.data.member,
            prfti_balance: formatPrice(parseFloat(prfti_balance)),
            usdpi_balance: formatPrice(parseFloat(usdpi_balance)),
            earned,
          });
        })
        .catch((err) => {
          setUser({
            id: '1',
            screen_name: 'NFT1',
            prfti_balance: formatPrice(
              parseFloat(
                (parseInt('12000000000000000000', 10) / 10 ** 18).toFixed(2)
              )
            ),
            usdpi_balance: formatPrice(
              parseFloat(
                (parseInt('10855430000000000000000', 10) / 10 ** 18).toFixed(2)
              )
            ),
            nft_img:
              'https://d2kf8ptlxcina8.cloudfront.net/YH5TFCE1QY-preview.png',
            nft_referrals: { current: 11, new: 2 },
            matrix_referrals: { current: 147, new: 12 },
            referral_link: 'http://www.example.com/i/xxxxx',
            earned: 0,
            aa_username: '',
          });
        });
    }
  }, [connectedMember]);

  useEffect(() => {
    if (connectedMember) {
      localStorage.setItem(
        '@PROFITi:lastPage',
        `${process.env.PUBLIC_URL}${location.pathname}`
      );
    }
  }, [connectedMember, location.pathname]);

  useEffect(() => {
    const lastPage = localStorage.getItem('@PROFITi:lastPage');
    // console.log(lastPage);
    if (lastPage) {
      setLastPageServed(lastPage);
    }
  }, [setLastPageServed]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@PROFITi:status');
    localStorage.removeItem('@PROFITi:lastPage');
    setUser(undefined);
    setConnectedMember(null);
    setStatus('');
    setConnected(false);
    setAccount('');
    setLastPageServed('');
  }, [setAccount, setConnectedMember, setLastPageServed]);

  const updateStatus = useCallback((statusData) => {
    localStorage.setItem('@PROFITi:status', JSON.stringify(statusData));
    setStatus(statusData);
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{
          connected,
          setConnected,
          user,
          status,
          setStatus: updateStatus,
          signOut,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
